import { PlusOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import React, { useEffect, useState } from "react";
import { DragDropContext } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import board_bg from "../../assets/images/board_bg.jpeg";
import { CreateList, UpdateItem } from "../../redux/actions/boards";
import { BOARD_LIST } from '../../redux/types';
import CommonModal from "../common-modal";
import UserAutoComplete from "../user-autocomplete";
import EditTask from './helpers/edit-task';
import TaskList from './helpers/task-list';


const BoardDetail = (props) => {
    const [isItemModalVisible, setItemIsModalVisible] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [selectedList, setSelectedList] = useState(null);

    const [showCreateList, setShowCreateList] = useState(false);
    const [windowHeight, setWindowHeight] = useState(500);

    useEffect(() => {
        setWindowHeight(window.innerHeight - 108);
    }, []);

    const onDragEnd = (result) => {
        if (props.board.board_type == 'template')
            return;
        let source_list_id = parseInt(result.source.droppableId.replace('col_', ''));
        let destination_list_id = parseInt(result.destination.droppableId.replace('col_', ''));
        let task_id = parseInt(result.draggableId.replace('task_', ''));

        let lists = props.board.lists;

        const source_list_index = lists.findIndex(el => el.id === parseInt(source_list_id));
        const destination_list_index = lists.findIndex(el => el.id === parseInt(destination_list_id));
        if (source_list_index === destination_list_index) {
            return;
        }

        let item = lists[source_list_index].items.filter(item => item.id === task_id)[0];

        const popIndex = lists[source_list_index]["items"].findIndex(ele => ele.id === item.id)
        lists[source_list_index]["items"].splice(popIndex, 1)
        lists[destination_list_index]["items"].splice(result.destination.index, 0, item);

        let board = props.board;
        board.lists = lists;
        props.dispatch({
            type: BOARD_LIST,
            payload: board
        });

        //Update Item 
        let itemPayload = {
            title: item.title,
            id: item.id,
            list: destination_list_id
        };
        props.dispatch(UpdateItem(destination_list_id, item.id, itemPayload));
    };

    const handleOnSubmitItemForm = (data) => {
        setItemIsModalVisible(false);
    }

    const handleonItemEdit = (item, list) => {
        setSelectedItem(item);
        setSelectedList(list);
        setItemIsModalVisible(!isItemModalVisible);
    }

    const handleListCreate = (data) => {
        data["board"] = props.board.id;
        props.dispatch(CreateList(data));
        setShowCreateList(false);
    }

    return (
        <div style={{ backgroundImage: "url(" + board_bg + ")", backgroundPosition: '50%', backgroundSize: 'cover' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', padding: '0px 10px' }}>
                <h4 style={{ color: '#fff' }}>You are in <i>{props.board.title}</i></h4>
                <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                    <Avatar.Group maxCount={5} size="default" maxStyle={{ color: '#f56a00', backgroundColor: '#fde3cf' }}>
                        {
                            props?.board?.board_members?.map((member, index) => {
                                return (
                                    <Avatar style={{ backgroundColor: '#f56a00', cursor: 'pointer' }} key={`board_member_avatar_${index}`} title={`This member is ${member.member_type} of this board.`}>
                                        {member.user.first_name[0]}{member.user.last_name[0]}
                                    </Avatar>
                                )
                            })
                        }
                    </Avatar.Group>
                    &nbsp;&nbsp;
                    {
                        (props.user.id == props.board.owner || props.board.is_co_owner) ? <UserAutoComplete board_id={props.board.id} board={props.board} /> : null
                    }
                </div>

            </div>


            <div style={{ display: 'flex', width: 'calc(100% - 1px)', overflow: 'scroll', height: `${windowHeight}px`, }}>
                {
                    // Create or Edit Item
                    isItemModalVisible ? <EditTask isItemModalVisible={isItemModalVisible} list={selectedList} item={selectedItem} onSubmitItemForm={handleOnSubmitItemForm} /> : null
                }
                <DragDropContext onDragEnd={onDragEnd}>
                    {
                        props.board?.lists?.map(list => {
                            return (
                                <TaskList board_id={props.board.id} list={list} key={`list_${list.id}`} onItemEdit={handleonItemEdit} />
                            )
                        })
                    }
                </DragDropContext>
                {
                    (props.user.id == props.board.owner) && (
                        <div className="task-list create-list">
                            <div className="list-title" onClick={() => { setShowCreateList(true) }}>
                                <PlusOutlined /> Add Another List
                            </div>
                            {
                                showCreateList && (<CommonModal
                                    open={showCreateList}
                                    toggle={() => setShowCreateList(!showCreateList)}
                                    what_for="Add New List"
                                    title={''}
                                    onOk={handleListCreate} />)
                            }
                        </div>
                    )
                }
            </div>
        </div>
    );
};

function mapStateToProps(state) {
    const { list, board_title, comments, checklists, board } = state.boards;
    const { user } = state.auth;
    return {
        list,
        board_title,
        comments,
        checklists,
        board,
        user
    }
}

export default connect(mapStateToProps)(BoardDetail);

